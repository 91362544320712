<template>
  <div v-if="icon" class="squared-icon__wrap" :style="iconStyles">
    <unicon class="squared-icon__icon" width="20" height="20" :name="icon" :fill="styles.themePrimary" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Color from 'color';

export default {
  name: 'SquaredIcon',

  props: {
    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      styles: 'app/getAppStyles',
    }),

    iconStyles() {
      const color = Color(this.styles.themePrimary);

      return {
        backgroundColor: `${color.fade(0.8)}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.squared-icon {
  &__wrap {
    height: rem(40);
    width: rem(40);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(6);
  }
}
</style>
