<template>
  <nav class="landing-topbar">
    <ul class="landing-topbar__items">
      <li>
        <language-switcher :locale="locale" :langs="langs" light width="74" @handle-locale="handleLocale" />
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import LanguageSwitcher from '@/components/common/LanguageSwitcher';

export default {
  name: 'LandingTopbar',

  components: {
    LanguageSwitcher,
  },

  computed: {
    ...mapGetters({
      locale: 'landing/getLocale',
      styles: 'app/getAppStyles',
    }),

    langs() {
      return [
        {
          value: 'es',
          label: 'Español',
        },
        {
          value: 'en',
          label: 'English',
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      setLandingLocale: 'landing/setLocale',
      fetchLandingContent: 'landing/fetchLandingContent',
    }),

    ...mapMutations({
      setAppLocale: 'app/setLocale',
    }),

    async handleLocale(locale) {
      this.setLandingLocale(locale);
      this.setAppLocale(locale);
      await this.fetchLandingContent();
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-topbar {
  width: 100%;
  height: rem(52);
  padding: rem(4) rem(8) rem(24);

  &__items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding-left: 0;
  }
}
</style>
