<template>
  <v-container fluid class="grey lighten-5 ma-0 pa-0">
    <template v-if="loadedData">
      <professional-data :data="data.professional" :specialties-title="specialtiesTitle" :specialties="specialties" />
      <services :services="data.services" :professional="data.professional" />
      <professional-experience :sections="data.sections" />
      <contact :data="data" />
      <social-networks :social-networks="data.socialNetworks" />
      <footer-landing />
    </template>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { baseNameRoutes } from '@/router/paths';
import { getHeader, professionalsUrl } from '@/config/config';
import ProfessionalData from '@/components/landing/ProfessionalData.vue';
import Services from '@/components/landing/Services.vue';
import ProfessionalExperience from '@/components/landing/ProfessionalExperience.vue';
import Contact from '@/components/landing/Contact.vue';
import SocialNetworks from '@/components/landing/SocialNetworks.vue';
import FooterLanding from '@/components/landing/FooterLanding.vue';
import { detectBrowserLocale } from '../../engine/framework/modules/i18n';

export default {
  name: 'LandingVue',
  components: { ProfessionalData, Services, ProfessionalExperience, Contact, SocialNetworks, FooterLanding },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      data: [],
      specialties: '',
      specialtiesTitle: '',
      loadedData: false,
    };
  },

  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
      appLogoPrimary: state => state.app.images.logoPrimary,
    }),
  },

  mounted() {
    if (this.checkIfItsOldRoute()) {
      this.redirectToDashboard();
    } else {
      this.getLandingData();
    }
  },

  methods: {
    getLandingData() {
      const lang =
        this.authUser && typeof this.authUser.lang !== 'undefined' ? this.authUser.lang : detectBrowserLocale();
      const slug = this.clearSlug(this.$route.path);
      const url = professionalsUrl + '/landing/' + slug + '?locale=' + lang;

      this.$http
        .get(url, { headers: getHeader() })
        .then(res => {
          this.data = res.body;
          this.specialties = this.data.professional.specialties.join(', ');
          this.specialtiesTitle =
            this.data.professional.specialties.length === 1
              ? this.$t('agenda.especialidad')
              : this.$t('user.especialidades');

          Object.values(this.data.sections).forEach(item => {
            item['content'] = item['content'].replace(/::/gi, '</br>');
          });

          this.loadedData = true;
        })
        .catch(err => {
          if (err.status === 404) {
            this.goNotFound();
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        });
    },

    clearSlug(slug) {
      if (slug.charAt(0) === '/') {
        slug = slug.substr(1);
      }
      return slug;
    },
    goNotFound() {
      this.$router.push({ name: baseNameRoutes.notFound });
    },

    checkIfItsOldRoute() {
      return this.$route.path.includes('account');
    },

    redirectToDashboard() {
      this.$router.push({ name: baseNameRoutes.dashboard });
    },
  },
};
</script>

<style scoped></style>
