<script>
import { mapGetters, mapActions } from 'vuex';
import LandingBaseModal from '@/components/landing-page/ui/modals/LandingBaseModal';
import scssColors from '@/sass/_variables.scss';
import CopyButton from '@/components/common/CopyButton';

export default {
  name: 'LandingModalMixin',

  components: {
    LandingBaseModal,
    CopyButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scssColors,
    };
  },

  computed: {
    ...mapGetters({
      showModal: 'landing/getModalVisibility',
      styles: 'app/getAppStyles',
    }),
  },

  methods: {
    ...mapActions({
      setModalVisibility: 'landing/setModalVisibility',
    }),

    handleClose() {
      this.setModalVisibility(this.componentId);
    },
  },
};
</script>
