export default item => {
  const icons = {
    1: 'user-square',
    2: 'book',
    9: 'graduation-cap',
    10: 'globe',
    11: 'medkit',
    12: 'award',
    13: 'band-aid',
    messaging: 'comment-alt-message',
    videoconsultation: 'clinic-medical',
    default: 'comment-exclamation',
  };

  return icons[item] || icons.default;
};
