<template>
  <landing-base-modal :show="showModal[componentId]" @close="handleClose">
    <template v-slot:title>
      <h2 class="landing-base-modal__title">{{ $t('publicLandingPage.consultationAddress') }}</h2>
    </template>
    <template v-slot:content>
      <div class="landing-base-modal__content">
        <p class="d-flex align-center">
          <unicon name="map-marker" width="18" height="21" :fill="scssColors.neutralColor1" />
          <span id="address" class="ml-2 mr-4">Av. de Andalucía, 27, 29006 Málaga</span>
          <copy-button element-to-copy="#address" />
        </p>
        <div>
          <doc-button tag="a" :link="`https://google.es`" link-target="_blank">
            {{ $t('publicLandingPage.viewMap') }}
          </doc-button>
        </div>
      </div>
    </template>
  </landing-base-modal>
</template>

<script>
import LandingModalMixin from '@/components/landing-page/ui/modals/LandingModalMixin';
import DocButton from '@/components/ui/DocButton';

export default {
  name: 'LandingMapModal',

  components: {
    DocButton,
  },

  mixins: [LandingModalMixin],

  data() {
    return {
      componentId: 'map',
    };
  },
};
</script>
