<template>
  <v-footer padless>
    <v-col class="text-center grey--text" cols="12">&copy; {{ appDisplayName }} {{ new Date().getFullYear() }}</v-col>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
    }),
  },
};
</script>

<style scoped></style>
