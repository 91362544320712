<template>
  <figure class="landing-avatar" :style="imageStyles">
    <img class="landing-avatar__image" :src="src" alt="avatar" />
  </figure>
</template>

<script>
export default {
  name: 'LandingAvatar',

  props: {
    src: {
      type: [String, Boolean],
      default: false,
    },
    width: {
      type: String,
      default: '80',
    },
    height: {
      type: String,
      default: '80',
    },
  },

  computed: {
    imageStyles() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-avatar {
  &__image {
    border-radius: rem(8);
    aspect-ratio: 1 / 1;
    width: 100%;
    max-width: 100%;
  }
}
</style>
