<template>
  <article v-if="!!Object.keys(section).length" class="landing-info-item">
    <div class="landing-info-item__inner" @click="handleClick">
      <unicon class="landing-info-item__icon" width="20" height="20" :name="icon" :fill="scssColors.neutralColor1" />
      <h3 class="landing-info-item__title">{{ section.name }}</h3>
      <unicon class="landing-info-item__chevron" name="angle-right-b" :fill="scssColors.neutralColor1" />
    </div>
    <landing-drawer :show="show" :content="getHtmlContent" :title="section.name" @close="show = false" />
  </article>
</template>

<script>
import getIcon from '@/components/landing-page/config/icons';
import LandingDrawer from '@/components/landing-page/ui/LandingDrawer';
import scssColors from '@/sass/_variables.scss';

export default {
  name: 'LandingInfoitem',

  components: {
    LandingDrawer,
  },

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      scssColors,
      show: false,
      listSeparator: '::',
    };
  },

  computed: {
    icon() {
      return getIcon(this.section.id);
    },

    getHtmlContent() {
      const tags = {
        1: 'p',
        2: 'ul',
      };

      const tag = tags[this.section.type];
      const content = tag === 'p' ? this.section.content : this.setHtmlList();

      return `
        <${tag}>
          ${content}
        </${tag}>
      `;
    },
  },

  methods: {
    handleClick() {
      this.show = !this.show;
    },

    setHtmlList() {
      return this.section.content
        .split(this.listSeparator)
        .map(item => `<li>${item}</li>`)
        .join('');
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-info-item {
  $--self: &;

  &__inner {
    display: flex;
    align-items: center;
    min-height: rem(56);
    cursor: pointer;
  }

  &:not(:last-child) {
    #{$--self}__inner {
      border-bottom: 1px solid $--color-neutral-2;
    }
  }

  &__icon {
    margin-right: rem(16);
    width: rem(20);
    height: rem(20);
  }

  &__title {
    font-size: rem(18);
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__chevron {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    opacity: 0.6;
  }
}
</style>
