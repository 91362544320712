<template>
  <landing-base-modal :show="showModal[componentId]" class="modal-phone" @close="handleClose">
    <template v-slot:title>
      <h2 class="landing-base-modal__title">{{ $t('publicLandingPage.consultationPhone') }}</h2>
    </template>
    <template v-slot:content>
      <div class="landing-base-modal__content">
        <p v-if="phone" class="d-flex align-center">
          <unicon name="phone" width="18" height="21" :fill="scssColors.neutralColor1" />
          <span id="phone" class="ml-2 mr-4 modal-phone__phone"> {{ prefix }} {{ phone | spacedPhoneNumber }} </span>
          <copy-button element-to-copy="#phone" />
        </p>
        <div>
          <doc-button tag="a" :link="`tel:${prefix + phone}`">{{ $t('publicLandingPage.call') }}</doc-button>
        </div>
      </div>
    </template>
  </landing-base-modal>
</template>

<script>
import { mapState } from 'vuex';
import LandingModalMixin from '@/components/landing-page/ui/modals/LandingModalMixin';
import DocButton from '@/components/ui/DocButton';

export default {
  name: 'LandingPhoneModal',

  components: {
    DocButton,
  },

  mixins: [LandingModalMixin],

  data() {
    return {
      componentId: 'phone',
    };
  },

  computed: {
    ...mapState({
      prefix: ({ landing } = {}) => landing?.content?.phone?.prefix,
      phone: ({ landing } = {}) => landing?.content?.phone?.number,
    }),
  },
};
</script>

<style lang="scss" scoped>
.modal-phone {
  &__phone {
    font-size: rem(20);
  }
}
</style>
