<template>
  <header v-if="content" class="landing-header" :style="headerStyles">
    <landing-topbar />
    <landing-avatar :src="content.image" />
    <div class="landing-header__title-wrapper">
      <h1 class="landing-header__title">{{ content.name }}</h1>
      <h2 class="landing-header__description">{{ content.specialties }}</h2>
      <p class="landing-header__details">{{ collegiateNumber }}</p>
    </div>
    <landing-mobile-actions :items="mobileActions" :overlap="overlapActions" />
    <landing-map-modal />
    <landing-phone-modal />
    <landing-info-modal />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Color from 'color';
import LandingAvatar from '@/components/landing-page/ui/LandingAvatar';
import LandingTopbar from '@/components/landing-page/layout/LandingTopbar';
import LandingMobileActions from '@/components/landing-page/layout/LandingMobileActions';
import LandingMapModal from '@/components/landing-page/ui/modals/LandingMapModal';
import LandingPhoneModal from '@/components/landing-page/ui/modals/LandingPhoneModal';
import LandingInfoModal from '@/components/landing-page/ui/modals/LandingInfoModal';

export default {
  name: 'LandingHeader',

  components: {
    LandingAvatar,
    LandingTopbar,
    LandingMobileActions,
    LandingMapModal,
    LandingPhoneModal,
    LandingInfoModal,
  },

  data() {
    return {
      overlapActions: true,
    };
  },

  computed: {
    ...mapGetters({
      content: 'landing/getHeaderContent',
      styles: 'app/getAppStyles',
    }),

    headerStyles() {
      const color = Color(this.styles.themePrimary);

      return {
        backgroundImage: `linear-gradient(306deg, ${color.lighten(0.5)} 5%, ${this.styles.themePrimary})`,
        marginBottom: this.overlapActions ? '32px' : null,
        paddingBottom: this.overlapActions ? null : '32px',
      };
    },

    mobileActions() {
      return [
        { name: 'map', icon: 'map-marker' },
        { name: 'phone', icon: 'phone' },
        { name: 'info', icon: 'info-circle' },
      ];
    },

    collegiateNumber() {
      return `${this.$t('publicLandingPage.collegiateNumber')}: ${this.content.collegiateNumber}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-bottom: rem(40); */

  &__title-wrapper {
    text-align: center;
    color: white;
  }

  &__title {
    font-size: rem(18);
    font-weight: 500;
    margin-top: rem(8);
  }

  &__description,
  &__details {
    font-weight: 400;
    margin-top: rem(8);
  }

  &__description {
    font-size: rem(16);
  }

  &__details {
    font-size: rem(12);
    margin-bottom: 0;
  }
}
</style>
