<template>
  <section class="landing-mobile-actions" :class="{ 'landing-mobile-actions--overlap': overlap }">
    <article v-for="item in items" :key="item.name">
      <span
        class="landing-mobile-actions__action"
        :class="{ 'landing-mobile-actions__action--active': isActive(item.name) }"
        @click="handleClick(item.name)"
      >
        <unicon :name="item.icon" :fill="styles.themePrimary" />
      </span>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LandingActions',

  props: {
    items: {
      type: Array,
      required: true,
    },
    overlap: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      styles: 'app/getAppStyles',
      showModal: 'landing/getModalVisibility',
    }),

    isActive() {
      return item => {
        return !!this.showModal[item];
      };
    },
  },

  methods: {
    ...mapActions({
      setModalVisibility: 'landing/setModalVisibility',
    }),

    handleClick(item) {
      this.setModalVisibility(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-mobile-actions {
  display: flex;
  justify-content: space-evenly;
  width: calc(100% - 32px);
  margin: rem(16) auto 0;
  padding: rem(12) 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

  &__action {
    $--action-dimension: rem(42);

    display: flex;
    justify-content: center;
    align-items: center;
    width: $--action-dimension;
    height: $--action-dimension;
    border-radius: 4px;
    background-color: $--color-neutral-3;
    border: solid 1px $--color-neutral-2;

    &--active,
    &:active {
      box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.45);
    }
  }

  &--overlap {
    transform: translateY(55%);
    margin-top: rem(-16);
  }
}
</style>
