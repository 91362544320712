<template>
  <landing-base-modal :show="showModal[componentId]" fullscreen class="modal-info" @close="handleClose">
    <template v-slot:title>
      <professional-modal-header />
    </template>
    <template v-slot:content>
      <div class="landing-base-modal__content">
        <landing-info v-if="sections" :sections="sections" />
      </div>
    </template>
  </landing-base-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import LandingModalMixin from '@/components/landing-page/ui/modals/LandingModalMixin';
import LandingInfo from '@/components/landing-page/ui/LandingInfo';
import ProfessionalModalHeader from '@/components/landing-page/ui/modals/ProfessionalModalHeader';

export default {
  name: 'LandingInfoModal',

  components: {
    LandingInfo,
    ProfessionalModalHeader,
  },

  mixins: [LandingModalMixin],

  data() {
    return {
      componentId: 'info',
    };
  },

  computed: {
    ...mapGetters({
      headerContent: 'landing/getHeaderContent',
      sections: 'landing/getContentSections',
      styles: 'app/getAppStyles',
    }),
  },
};
</script>
