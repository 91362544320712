<template>
  <v-layout
    align-center
    justify-center
    :class="{ 'bg-image': data.image }"
    :style="'--profile-image:url(' + data.image + ')'"
  >
    <v-sheet class="transparent" max-width="900px" width="100%">
      <v-row class="my-6 py-10" no-gutters>
        <v-col xs="12" sm="4" align="center">
          <d-avatar
            class="avatar foto_usuario"
            :text="data.name"
            size="150"
            rounded
            :two-letter="true"
            :src="data.image"
          />
        </v-col>

        <v-col xs="12" sm="8">
          <h1 class="h1 mb-3">{{ data.name }}</h1>
          <div v-if="data.collegiateNumber" class="mb-1 grey--text text--darken-1">
            {{ $t('user.num_colegiado') }}: {{ data.collegiateNumber }}
          </div>

          <div v-if="specialties !== ''" class="mb-2 grey--text text--darken-1">
            {{ specialtiesTitle }}: {{ specialties }}
          </div>

          <div v-if="data.biography" class="pt-2 grey--text text--darken-3">{{ data.biography }}</div>
        </v-col>
      </v-row>
    </v-sheet>
  </v-layout>
</template>

<script>
import DAvatar from '../DAvatar.vue';
export default {
  name: 'ProfessionalData',
  components: { DAvatar },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
    specialtiesTitle: {
      type: String,
      default: '',
    },
    specialties: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.bg-image {
  overflow: hidden;
  position: relative;
}

.bg-image .v-sheet {
  z-index: 2;
  text-shadow: 0 0 4px #fff;
}

.bg-image::before {
  content: ' ';
  background: var(--profile-image);

  filter: blur(30px);
  -webkit-filter: blur(30px);

  position: absolute;
  width: 200%;
  height: 200%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
}

.bg-image::after {
  content: ' ';
  background: linear-gradient(0deg, rgba(250, 250, 250, 1) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
