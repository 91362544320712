<template>
  <v-row class="mb-6" no-gutters>
    <v-col cols="12" xs="12" class="px-2 mb-5 mb-md-0" align="center">
      <v-btn
        v-if="socialNetworks.twitter !== null && socialNetworks.twitter !== ''"
        v-tooltip="'Twitter'"
        class="social-link mx-2"
        fab
        dark
        color="grey"
        :href="socialNetworks.twitter"
        target="_blank"
      >
        <v-icon dark>mdi-twitter</v-icon>
      </v-btn>

      <v-btn
        v-if="socialNetworks.facebook !== null && socialNetworks.facebook !== ''"
        v-tooltip="'Facebook'"
        class="social-link mx-2"
        fab
        dark
        color="#3b5998"
        :href="socialNetworks.facebook"
        target="_blank"
      >
        <v-icon dark>mdi-facebook</v-icon>
      </v-btn>

      <v-btn
        v-if="socialNetworks.linkedin !== null && socialNetworks.linkedin !== ''"
        v-tooltip="'LinkedIn'"
        class="social-link mx-2"
        fab
        dark
        color="#0077b5"
        :href="socialNetworks.linkedin"
        target="_blank"
      >
        <v-icon dark>mdi-linkedin</v-icon>
      </v-btn>

      <v-btn
        v-if="socialNetworks.instagram !== null && socialNetworks.instagram !== ''"
        v-tooltip="'Instagram'"
        class="social-link mx-2"
        fab
        dark
        color="#e1306c"
        :href="socialNetworks.instagram"
        target="_blank"
      >
        <v-icon dark>mdi-instagram</v-icon>
      </v-btn>

      <v-btn
        v-if="socialNetworks.youtube !== null && socialNetworks.youtube !== ''"
        v-tooltip="'Youtube'"
        class="social-link mx-2"
        fab
        dark
        color="#ff0000"
        :href="socialNetworks.youtube"
        target="_blank"
      >
        <v-icon dark>mdi-youtube</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SocialNetworks',
  props: {
    socialNetworks: {
      type: Object,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.social-link {
  text-decoration: none;
}
</style>
