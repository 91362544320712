<template>
  <v-row class="mb-6" justify="center" align="center" no-gutters>
    <v-col v-if="data.phone.address && data.phone.address !== ''" cols="12" xs="12" md="4" class="px-2 mb-5 mb-md-0">
      <v-card flat class="mx-auto" max-width="400" align="center">
        <v-icon large class="mt-5">mdi-map-marker</v-icon>
        <v-card-subtitle class="pb-0 mb-4 text-uppercase">{{ $t('common.address') }}</v-card-subtitle>
        <v-card-text class="text--primary">
          <div class="mb-4">{{ data.address }}</div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col v-if="data.phone.number && data.phone.number !== ''" cols="12" xs="12" md="4" class="px-2 mb-5 mb-md-0">
      <v-card flat class="mx-auto" max-width="400" align="center">
        <v-icon large class="mt-5">mdi-cellphone-android</v-icon>
        <v-card-subtitle class="pb-0 mb-4 text-uppercase">{{ $t('patient.phone') }}</v-card-subtitle>
        <v-card-text class="text--primary">
          <div class="mb-4">{{ data.phone.number }}</div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col v-if="data.web && data.web !== ''" cols="12" xs="12" md="4" class="px-2 mb-5 mb-md-0">
      <v-card flat class="mx-auto" max-width="400" align="center">
        <v-icon large class="mt-5">mdi-laptop</v-icon>
        <v-card-subtitle class="pb-0 mb-4 text-uppercase">Web</v-card-subtitle>
        <v-card-text class="text--primary">
          <div v-if="data.web !== null" class="mb-4">
            <a :href="data.web">{{ data.web }}</a>
          </div>
          <p v-else>
            <br />
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
