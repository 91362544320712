<template>
  <v-container v-if="!showDontHaveService">
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent">
          <h1 class="headline">{{ $t('landing.titulo') }}</h1>
          <v-spacer />
          <v-btn
            data-test="settings-web-button-preview"
            v-if="miweb.active && miweb.url !== ''"
            :disabled="disabledButton"
            text
            rounded
            depressed
            color="primary"
            class="mr-2"
            @click="vistaPrevia()"
          >
            <v-icon class="mr-2">mdi-eye</v-icon>
            {{ $t('landing.go_to_my_web') }}
          </v-btn>
          <v-btn
            data-test="settings-web-button-save"
            :loading="isSaving"
            :disabled="disabledButton || isSaving"
            rounded
            depressed
            color="primary"
            @click="guardar()"
            >{{ $t('common.save') }}</v-btn
          >
        </v-toolbar>
        <landing
          :idusuario="usuario_id"
          :landing="miweb"
          :secciones="secciones"
          :show-legend="showLegend"
          :esadmin="false"
          @landingModificada="onLandingModificadaChild"
          @save="guardar"
        />
      </v-card>
    </v-row>
  </v-container>
  <div v-else>
    <no-service-granted view="miweb" />
  </div>
</template>

<script>
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import Landing from '@/components/ehealth/ajustes/Landing.vue';
import Alerts from '@/mixins/Alerts';
import { haveEprescriptionActive } from '@/utils';
import { nameEnv, getMiWebUrl, getHeader } from '../../config/config';

export default {
  components: {
    Landing,
    NoServiceGranted,
  },
  mixins: [Alerts],
  data() {
    return {
      isLoading: false,
      isSaving: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      fecha_actual: new Date(),
      nombreEntorno: nameEnv,
      timeout: 5000,
      disabledButton: false,
      showLegend: false,
      mode: '',
      usuario_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
      miweb: {},
      secciones: [],
      dialogSalirSinGuardar: false,
      miWebModificada: false,
      goToVistaPrevia: false,
      showDontHaveService: false,
    };
  },
  created() {
    if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    } else {
      this.isLoading = true;
      this.$http
        .post(getMiWebUrl, { user_id: this.authUser.id, accion: 'index' }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.miweb = response.data.landing;
            this.secciones = response.data.secciones;
            this.secciones.forEach(sec => {
              if (this.$i18n.locale === 'es' || this.$i18n.locale === 'ca') {
                sec.name = sec.nombre;
              }
            });

            if (this.miweb.direccion === null) {
              this.miweb.direccion = this.miweb.direccion_perfil;
            }
            this.showLegend = true;
          } else {
            this.$router.push({ path: '/' });
          }
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    validarForm() {
      const ok = true;
      return ok;
    },
    guardar() {
      if (this.validarForm() && !this.disabledButton) {
        this.isSaving = true;
        this.$http
          .post(
            getMiWebUrl,
            { user_id: this.authUser.id, accion: 'save', landing: this.miweb },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.toastSuccess(this.$t('success.save_common'));
              this.miweb.url = response.data.url;
            } else {
              this.toastSuccess(this.$t('errors.try_again'));
            }
            this.miWebModificada = false;
          })
          .catch(err => {
            this.$log.error(err);
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
    vistaPrevia() {
      if (this.miWebModificada) {
        this.dialogSalirSinGuardar = true;
        this.goToVistaPrevia = true;
      } else {
        window.open(this.miweb.url, '_blank');
      }
    },
    onLandingModificadaChild(respuesta) {
      this.miWebModificada = respuesta;
    },
    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      if (this.goToVistaPrevia) {
        window.open(this.miweb.url);
      } else {
        this.pageNext();
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.miWebModificada && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
