<template>
  <section class="landing-services">
    <header class="landing-services__header">
      <h2 class="landing-services__title">
        {{ $t('publicLandingPage.availableServicesTitle') }}
      </h2>
    </header>
    <landing-service-item
      v-for="service in services"
      :key="getKey(service)"
      :service="service"
      @handle-service="handleServiceModal"
    />
    <landing-service-modal :service="activeService" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LandingServiceItem from '@/components/landing-page/layout/LandingServiceItem';
import LandingServiceModal from '@/components/landing-page/ui/modals/LandingServiceModal';

export default {
  name: 'LandingServices',

  components: {
    LandingServiceItem,
    LandingServiceModal,
  },

  data() {
    return {
      activeService: null,
    };
  },

  computed: {
    ...mapGetters({
      services: 'landing/getServices',
    }),

    getKey() {
      return service => `${service.id}-${service.type}`;
    },
  },

  methods: {
    ...mapActions({
      setModalVisibility: 'landing/setModalVisibility',
    }),

    handleServiceModal(service) {
      this.activeService = { ...service };
      this.setModalVisibility('service');
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-services {
  &__header {
    padding: rem(24) 0;
  }

  &__title {
    color: $--color-neutral-1;
    font-size: rem(18);
    font-weight: 500;
    text-align: center;
  }
}
</style>
