<template>
  <v-layout align-center justify-center class="text-center">
    <v-sheet class="transparent pt-5" max-width="900px" width="100%">
      <v-row no-gutters>
        <template v-if="services.length > 0">
          <v-col
            v-for="(service, index) in services"
            :key="index"
            cols="12"
            xs="4"
            sm="6"
            md="4"
            class="px-2 mb-5 mb-1"
          >
            <v-card flat class="mx-auto" max-width="100%" height="100%" align="center">
              <div class="pt-7">
                <v-avatar size="70" color="secondary" class="white--text" outline>
                  <v-icon v-if="service.type === 'messaging'" x-large class="white--text">mdi-message-text</v-icon>
                  <v-icon v-if="service.faceToFace === 1" x-large class="white--text"
                    >mdi-office-building-marker</v-icon
                  >
                  <v-icon v-else-if="service.type === 'videoconsultation'" x-large class="white--text"
                    >mdi-video</v-icon
                  >
                  <v-icon v-if="service.type === 'subscription'" x-large class="white--text">mdi-sync-circle</v-icon>
                  <v-icon v-if="service.type === 'pack'" x-large class="white--text">mdi-package-variant-closed</v-icon>
                </v-avatar>
              </div>
              <v-card-text class="text--primary">
                <h3 class="title mb-4">{{ service.name }}</h3>
                <v-btn depressed rounded color="primary" class="mb-7" @click="goToHiring(service)">
                  <span v-if="service.type === 'messaging'">{{ $t('mensajeria.mensaje') }}</span>
                  <span v-if="service.type === 'videoconsultation' && service.faceToFace === 1">{{
                    $t('common.presential')
                  }}</span>
                  <span v-else-if="service.type === 'videoconsultation'">{{ $t('common.videoconference') }}</span>
                  <span v-if="service.type === 'subscription'">{{ $t('suscripciones.suscripcion') }}:</span>
                  <span v-if="service.type === 'pack'">{{ $t('common.pack') }}:</span>
                  <!--              {{ $t('common.presential') }}-->
                  <span v-if="service.price > 0">{{ service.price }} €</span>
                  <span v-else-if="service.price === 0 && (service.type === 'messaging' || service.type === 'pack')"
                    >&nbsp;{{ $t('common.gratuito') }}</span
                  >
                  <span
                    v-else-if="
                      service.price === 0 && (service.type === 'videoconsultation' || service.type === 'subscription')
                    "
                    >{{ $t('common.gratuita') }}</span
                  >
                </v-btn>

                <div class="mb-4 grey--text text--darken-2">{{ service.description }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-sheet>
  </v-layout>
</template>

<script>
export default {
  name: 'Services',
  props: {
    services: {
      type: Array,
      default: () => [],
    },
    professional: {
      type: Object,
      default: () => {
        /** */
      },
    },
  },
  methods: {
    goToHiring(service) {
      const serviceType = this.setServiceType(service.type);
      const url = '/hiring/' + this.professional.id + '/' + serviceType + '/' + service.id;
      this.$router.push(url).catch(() => {
        console.error('Vue router catched error in redirect');
      });
    },
    setServiceType(type) {
      let serviceType = '';
      if (type === 'messaging') {
        serviceType = 'message';
      } else if (type === 'videoconsultation') {
        serviceType = 'appointment';
      } else {
        serviceType = type;
      }
      return serviceType;
    },
  },
};
</script>

<style scoped></style>
