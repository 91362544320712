<template>
  <v-layout align-center justify-center class="pt-10">
    <v-card flat max-width="900px" width="100%" class="mx-5">
      <v-card v-for="(section, index) in sections" :key="index" flat>
        <v-card-text>
          <div class="d-inline-block">
            <h3 class="title text--primary">{{ section.name }}</h3>
            <v-sheet height="8px" width="100%" class="secondary mb-5 rounded" />
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="section.content">{{ section.content }}</p>
        </v-card-text>
      </v-card>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  name: 'ProfessionalExperience',
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
