<template>
  <component
    :is="tag"
    v-bind="getProps"
    class="doc-button"
    :class="{ 'doc-button--text': text }"
    :style="styles"
    @click="handleClick"
  >
    <span class="doc-button__inner">
      <unicon v-if="icon" :name="icon" fill="white" class="doc-button__icon" />
      <span class="doc-button__text">
        <template v-if="hasDefaultSlot">
          <slot />
        </template>
        <template v-else>
          {{ defaultText }}
        </template>
      </span>
    </span>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DocButton',

  props: {
    tag: {
      type: String,
      default: 'button',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    linkTarget: {
      type: String,
      default: '_self',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      defaultText: 'Ok',
    };
  },

  computed: {
    ...mapGetters({
      appStyles: 'app/getAppStyles',
    }),

    hasDefaultSlot() {
      return !!this.$slots.default;
    },

    styles() {
      return {
        backgroundColor: this.text ? 'transparent' : this.appStyles.themePrimary,
      };
    },

    getProps() {
      return this.tag === 'a'
        ? {
            href: this.link,
            target: this.linkTarget,
          }
        : null;
    },
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.doc-button {
  /* reset */
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  box-shadow: none;
  border: none;
  /* font: get-var(font-sans);
  font-family: get-var(font-sans); */
  background: none;
  min-height: rem(32);
  outline-color: var(--v-secondary-base);
  /* styles */
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  transition: background 250ms ease-in-out, transform 150ms ease;
  height: rem(40);
  font-size: rem(16);
  font-weight: 500;
  color: white;
  border-radius: rem(4);
  padding: 0 rem(12);
  &:focus {
    outline-color: var(--v-secondary-base);
  }
  &:hover,
  &:active {
    opacity: 0.9;
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  &__icon {
    margin-right: rem(8);
  }

  &--text {
    color: var(--v-primary-base);
  }
}
</style>
