<template>
  <section class="landing-info">
    <landing-info-item v-for="section in sections" :key="section.id" :section="section" />
  </section>
</template>

<script>
import LandingInfoItem from '@/components/landing-page/ui/LandingInfoItem';

export default {
  name: 'LandingInfo',

  components: {
    LandingInfoItem,
  },

  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-info {
}
</style>
