<template>
  <article v-if="service" class="landing-service-item" @click="handleClick">
    <div class="landing-service-item__inner">
      <service-header :service="service" />
      <unicon class="landing-service-item__chevron" name="angle-right-b" :fill="scssColors.neutralColor1" />
    </div>
  </article>
</template>

<script>
import scssColors from '@/sass/_variables.scss';
import ServiceHeader from '@/components/landing-page/ui/ServiceHeader';

export default {
  name: 'LandingServiceItem',

  components: {
    ServiceHeader,
  },

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      scssColors,
    };
  },

  methods: {
    handleClick() {
      this.$emit('handle-service', this.service);
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-service-item {
  &__inner {
    display: flex;
    align-items: center;
    background-color: white;
    padding: rem(16);
    margin-bottom: rem(16);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  }

  &__chevron {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    opacity: 0.6;
  }
}
</style>
