<template>
  <transition name="slide-right">
    <section v-if="show" class="landing-drawer">
      <div class="landing-drawer__inner">
        <header class="landing-drawer__header">
          <span class="landing-drawer__close">
            <unicon name="angle-left-b" :fill="scssColors.neutralColor1" @click="handleClose" />
          </span>
          <h4 v-if="title" class="landing-drawer__title">{{ title }}</h4>
        </header>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="landing-drawer__body" v-html="content" />
      </div>
    </section>
  </transition>
</template>

<script>
import scssColors from '@/sass/_variables.scss';

export default {
  name: 'LandingDrawer',

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      scssColors,
    };
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-drawer {
  $--header-height: rem(65);

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;

  &__inner {
    width: 100%;
    height: calc(100% - 24px);
    background-color: $--color-neutral-3;
    overflow: hidden;
  }

  &__header {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    padding: rem(12) rem(16);
    border-bottom: 1px solid $--color-neutral-2;
    height: $--header-height;
  }

  &__close {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: rem(24);
    width: rem(24);
  }

  &__title {
    font-size: rem(18);
    font-weight: 500;
    line-height: rem(24);
    margin-left: rem(16);
    color: $--color-base;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__body {
    padding: rem(24);
    overflow-y: scroll;
    height: calc(100vh - #{$--header-height});
  }
}
</style>
