<template>
  <landing-base-modal :show="showModal[componentId]" fullscreen @close="handleClose">
    <template v-slot:title>
      <professional-modal-header />
    </template>
    <template v-slot:content>
      <div class="landing-base-modal__content">
        <div>
          <service-header :service="service" />
          <doc-button>botón</doc-button>
        </div>
      </div>
    </template>
  </landing-base-modal>
</template>

<script>
import LandingModalMixin from '@/components/landing-page/ui/modals/LandingModalMixin';
import DocButton from '@/components/ui/DocButton';
import ProfessionalModalHeader from '@/components/landing-page/ui/modals/ProfessionalModalHeader';
import ServiceHeader from '@/components/landing-page/ui/ServiceHeader';

export default {
  name: 'LandingServiceModal',

  components: {
    DocButton,
    ProfessionalModalHeader,
    ServiceHeader,
  },

  mixins: [LandingModalMixin],

  props: {
    service: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      componentId: 'service',
    };
  },
};
</script>
