<template>
  <section class="landing-page">
    <landing-header />
    <landing-services />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import LandingHeader from '@/components/landing-page/layout/LandingHeader';
import LandingServices from '@/components/landing-page/layout/LandingServices';

export default {
  name: 'LandingPage',

  components: {
    LandingHeader,
    LandingServices,
  },

  async created() {
    this.setSlug(this.$route.path);
    await this.fetchLandingContent();
  },

  methods: {
    ...mapActions({
      setSlug: 'landing/setSlug',
      fetchLandingContent: 'landing/fetchLandingContent',
    }),
  },
};
</script>

<style lang="scss" scoped>
.landing-page {
  min-height: 100vh;
  background-color: $--color-neutral-3;
  font-family: $--font-sans;
}
</style>
