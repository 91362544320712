<template>
  <transition name="fade">
    <div
      v-show="show"
      class="landing-base-modal"
      :class="{ 'is-visible': show, 'landing-base-modal--fullscreen': fullscreen }"
    >
      <transition name="slide-up">
        <div v-show="show" v-click-outside="handleClickOutside" class="landing-base-modal__inner">
          <span class="landing-base-modal__close">
            <unicon name="multiply" :fill="scssColors.neutralColor1" @click="handleClose" />
          </span>
          <slot name="title" />
          <slot name="content" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import scssColors from '@/sass/_variables.scss';

export default {
  name: 'LandingBaseModal',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scssColors,
      activeCssClass: 'is-visible',
    };
  },

  computed: {
    ...mapGetters({
      styles: 'app/getAppStyles',
    }),
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },

    handleClickOutside({ target }) {
      if (typeof target.className !== 'string') {
        return;
      }

      const isActive = this.$el.className.includes(this.activeCssClass);
      const eventInComponent = target.className.includes(this.$el.className);

      isActive && eventInComponent && this.handleClose();
    },
  },
};
</script>

<style lang="scss">
.landing-base-modal {
  $--self: &;
  $--base-transition: all 0.25s ease-out;

  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;

  &__inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: white;
    border-top-left-radius: rem(16);
    border-top-right-radius: rem(16);
    z-index: 9;
    padding: rem(24) rem(8);
    transition: $--base-transition;
  }

  &__close {
    position: absolute;
    top: rem(16);
    right: rem(16);
    cursor: pointer;
  }

  &::after {
    content: '';
    background-color: black;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    transition: $--base-transition;
    opacity: 0.62;
  }

  &__title,
  &__content {
    padding-left: rem(8);
  }

  &__title {
    font-size: rem(18);
    font-weight: 500;
  }
  &__content {
    margin-top: rem(8);
    font-size: rem(14);
    color: $--color-neutral-1;
  }

  &--fullscreen {
    #{$--self}__inner {
      height: 100vh;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 0;
    }

    &::after {
      display: none;
    }

    #{$--self}__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: rem(12) rem(16);
      border-bottom: 1px solid $--color-neutral-2;
      height: $--fullscreen-modal-header-height;
    }

    #{$--self}__header-content {
      margin-left: rem(12);
    }

    #{$--self}__title,
    #{$--self}__subtitle {
      color: $--color-neutral-1;
    }

    #{$--self}__title {
      font-size: rem(14);
      padding-left: 0;
    }

    #{$--self}__subtitle {
      font-size: rem(12);
      font-weight: 400;
    }

    #{$--self}__content {
      background-color: $--color-neutral-3;
      margin-top: 0;
      min-height: calc(100vh - #{$--fullscreen-modal-header-height});
      padding: rem(16);
    }
  }
}
</style>
