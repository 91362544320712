<template>
  <header v-if="headerContent" class="landing-base-modal__header">
    <landing-avatar :src="headerContent.image" height="40" width="40" />
    <div class="landing-base-modal__header-content">
      <h4 class="landing-base-modal__title">{{ headerContent.name }}</h4>
      <h5 class="landing-base-modal__subtitle">{{ headerContent.specialties }}</h5>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import LandingAvatar from '@/components/landing-page/ui/LandingAvatar';

export default {
  name: 'ProfessionalModalHeader',

  components: {
    LandingAvatar,
  },

  computed: {
    ...mapGetters({
      headerContent: 'landing/getHeaderContent',
    }),
  },
};
</script>
