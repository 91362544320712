<template>
  <div v-if="icon" class="service-header">
    <squared-icon :icon="icon" />
    <div class="service-header__content">
      <h3 v-if="service.name" class="service-header__title">{{ service.name }}</h3>
      <p v-if="price" class="service-header__price">{{ price }}</p>
    </div>
  </div>
</template>

<script>
import SquaredIcon from '@/components/landing-page/ui/SquaredIcon';
import getIcon from '@/components/landing-page/config/icons';
import { DEFAULT_CURRENCY_SYMBOL } from '@/config/constants';

export default {
  name: 'ServiceHeader',

  components: {
    SquaredIcon,
  },

  props: {
    service: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    icon() {
      return this.service && getIcon(this.service.type);
    },

    price() {
      return !isNaN(this.service.price) ? `${this.service.price} ${DEFAULT_CURRENCY_SYMBOL} / 60 min.` : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-header {
  display: flex;
  align-items: center;
  &__content {
    margin-left: rem(16);
  }

  &__title,
  &__price {
    color: $--color-base;
    font-size: rem(16);
  }

  &__title {
    font-weight: 400;
  }

  &__price {
    margin-bottom: 0;
    font-weight: 500;
  }
}
</style>
